.search-query {
   margin-top: 20px;
   width: 30%;
}
.col-xs-10 {
   width: 50%;
   margin-left: auto;
   margin-right: auto;
   text-align: center;
}

.col-xs-10 select {
   width: 200px;
   text-align: center;
}

.container.search {
   position: absolute;
   opacity: 0;
   transition: opacity 0.5s ease-in-out;
}

.container.search.visible {
   position: relative;
   opacity: 1;
}

.selects-container p {
   margin-top: 20px;
   margin-bottom: 1px;
   color: white;
   font-family: "Kalnia", serif;
   text-shadow: 1px 1px 1px black;
   font-size: 17px;
}

.search-query input:focus {
   box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.1) !important;
   border-color: var(--gray);
}

.glow {
   box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.1) !important;
}

select {
   padding: 5px;
   background-color: var(--gray) !important;
   color: white;
   font-family: "Kalnia", serif;
   text-shadow: 1px 1px 1px black;
   font-size: 15px;
   border: solid 1px var(--white);
   border-radius: 10px;
   scrollbar-width: thin;
   /*Arrow*/
   -moz-appearance: none;
   -webkit-appearance: none;
   appearance: none;
   cursor: pointer;
}
select:focus {
   box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.1) !important;
}
select option,
option:hover {
   cursor: pointer !important;
   font-family: "Kalnia", serif;
   text-shadow: 1px 1px 1px black;
   font-size: 18px;
}

.searchForm {
   margin: 30px 0px 6px 0px;
}

@media (max-width: 991px) {
   .search-query {
      width: 50%
   }
}
@media (max-width: 575px) {
   .search-query {
      width: 70%
   }
   .col-xs-10 select {
      width: 150px;
   }
   .navbar{
      border-bottom-right-radius:20px
   }
}
@media (max-width: 390px) {
   .selects-container p{
      font-size: 15px;
   }

   .col-xs-10 select {
      width: 130px;
      font-size: 14px;
   }
}
