.trailer-window {
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.6);
   position: absolute;
   z-index: 1;
   display: none;
}

.trailer-window.show {
   display: flex;
}

.trailer-video {
   margin: auto;
   margin-right: 0;
}
.trailer-video iframe {
   margin: auto;
   display: flex;
   width: 960px;
   height: 540px;
}

.trailer-exit-box {
   margin: auto;
   margin-left: 0px;
   margin-right: auto;
   height: 540px;
}

.trailer-exit {
   height: 65px;
   width: 65px;
   border-top-right-radius: 10%;
   border-bottom-right-radius: 10%;
   /* position: absolute;
    left: 95%;
    top: 2%;*/
   background-color: rgba(0, 0, 0, 1);
   margin: auto !important;
   display: inline-block;
   cursor: pointer;
   text-align: center;
   padding-top: 6px;
}

.trailer-exit svg {
   margin: auto;
   height: 36px;
   width: 36px;
}

.trailer-exit span {
   color: white;
   margin: auto;
   color: rgb(238, 238, 238);
   cursor: pointer;
   display: block;
   font-family: "NewsGothicRegular";
   font-size: 14px;
   font-weight: 100 !important;
   line-height: 19px;
   text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px;
}

.material-symbols-outlined {
   font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
   height: 30px;
   width: 30px;
   color: white;
   position: absolute;
}

@media (max-width: 1023px) {
   .trailer-video iframe,
   .trailer-video {
      width: 90%;
   }

   .trailer-exit {
      position: absolute;
      top: 0px;
      right: 0;
      border-radius: 0;
   }
}
@media (max-width: 767px) {
   .trailer-video iframe {
      height: 400px;
   }
}

@media (max-width: 575px) {
   .trailer-video iframe {
      height: 300px;
   }
}
@media (max-width: 390px) {
   .trailer-video iframe {
      height: 250px;
   }
}

@media (max-width: 575px) {
   .trailer-video iframe,
   .trailer-video {
      width: 100%;
   }
}
