h3 {
   color: white;
   /*background-color: rgba(0, 0, 0, 0.6);*/
   background: rgb(0, 0, 0);
   background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
   padding: 10px 20px !important;
   font-family: "Kalnia";
   margin-bottom: 0px;
}

.swiper-button-next,
.swiper-button-prev {
   color: white !important;
   text-shadow: 4px 1px 3px black;
   height: 290px !important;
   margin-top: 0 !important;
   top: 10px !important;
   bottom: 0 !important;
   width: calc(var(--swiper-navigation-size) / 44 * 50) !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
   text-shadow: 3px 1px 2px black;
}

.swiper-button-next {
   right: 0 !important;
   background: rgb(0, 0, 0);
   background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}
.swiper-button-next:hover {
   background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
}

.swiper-button-prev {
   left: 0 !important;
   background: rgb(0, 0, 0);
   background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
}
.swiper-button-prev:hover {
   background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}

.swiper {
   width: 100%;
   height: 100%;
}

.row.genre {
   margin-top: 10px;
   margin-bottom: 20px !important;
}

.swiper-slide {
   text-align: center;
   font-size: 18px;
   background: var(--dark-gray);
   height: 340px !important;
   /*auto*/
   padding-top: 10px;
   /* Center slide text vertically */
   display: flex;
   justify-content: center;
   align-items: center;
}

.swiper-slide img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
   box-shadow: 0 0 10px black;
}

.swiper-slide img:hover {
   opacity: 0.9;
}

@media (max-width: 600px) {
   .row.genre {
      margin-right: 10px !important;
      margin-left: 10px !important;
      margin-top: 30px !important;
   }
}
