:root {
    --red: #ef3e36;
    --light-red:#D77A61;
    --dark-red:#931F1D;
    --blue: #17bebb;
    --black: #2e282a;
    --gray: #8b8bae;
    --light-gray: #a4a4c2;
    --yellow: #fff275;
    --green: #849324;
    --white: #f2e9e4;
    --purple: #5c4ebd;
    --green-yellow: #b4bd4e;
    --dark: #21221d;
    --dark-gray:#223843;
    --dark-blue: #262d46;
}
